.header {
    color: $white;
    top: 4.2rem;
    transition: color .3s linear;
    z-index: 12;
    @include media-breakpoint-down(lg) {
        top: 5.4rem;
        position: absolute;
        width: 100%;
    }
    .navbar {
        background: transparent;
        width: 100%;
        left: 0;
        top: 0;
        border: none;
        margin: 0;
        padding: 0;
        z-index: 120;
        &-brand {
            color: $white;
            & > svg {
                width: 17.6rem;
                height: 8rem;
                @include media-breakpoint-down(lg) {
                    width: 9.1rem;
                    height: 4.1rem;
                }
            }
            & > .special-logo {
                height: 9.6rem;
                @include media-breakpoint-down(lg) {
                    height: 5.1rem;
                }
            }
        }
        &-collapse {
            border-bottom: 1px solid $light6;
            height: auto!important;
            transition: opacity .3s ease-in;
            position: relative;
            @include media-breakpoint-down(lg) {
                display: none;
            }
            .navbar-nav {
                margin-left: auto;
                padding-right: 10rem;
                position: relative;
                .nav-item {
                    margin-right: 40px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .dropdown-toggle {
                        background: transparent;
                        border: none;
                        box-shadow: none;
                        outline: none;
                        border-radius: 0;
                        border: none;
                        padding: 0;
                        font-weight: 500;
                        font-size: 12px;
                        letter-spacing: 0.02em;
                        text-transform: uppercase;
                        text-align: left;
                        color: rgba(168, 59, 59, .85);
                        -ms-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                        position: relative;
                        vertical-align: top;
                        &:after {
                            display: none;
                        }

                        &:before {
                            position: absolute;
                            content: "";
                            width: 100%;
                            height: 4px;
                            background: $light;
                            left: 0;
                            width: 0;
                            bottom: 0;
                            opacity: 0;
                            -ms-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            -webkit-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                        }
                        strong {
                            display: block;
                            font-weight: bold;
                        }
                        &:hover {
                            color: $light;
                            &:before {
                                width: 100%;
                                opacity: 1;
                            }
                        }
                    }

                    .dropdown-menu {
                        min-width: 100%;
                        background: transparent;
                        width: 778px;
                        border: none;
                        border-radius: 0;
                        border-bottom: 1px solid $light7;
                        left: 0;
                        display: table;
                        opacity: 0;
                        visibility: hidden;
                        -webkit-transition: all 0.3s linear;
                        transition: all 0.3s linear;
                        @include media-breakpoint-down(xl) {
                            width: 767px;
                        }
                        li {
                            display: inline-block;
                            margin: 0 70px 0 0;
                            vertical-align: bottom;
                            @include media-breakpoint-down(xl) {
                                margin: 0 50px 0 0;
                            }
                            &:last-child {
                                margin: 0;
                            }
                            .dropdown-item {
                                padding: 2.6rem 0 1.8rem 0;
                                text-transform: uppercase;
                                height: auto;
                                color: rgba(255,255,255, .85);
                                min-height: 60px;
                                -webkit-transition: all 0.3s linear;
                                transition: all 0.3s linear;
                                &:before {
                                    opacity: 0;
                                    width: 0;
                                }
                                &:hover {
                                    color: $light;
                                    &:before {
                                        opacity: 1;
                                        width: 100%;
                                    }
                                }
                            }
                            &.active {
                                .dropdown-item {
                                    color: $light;
                                    &:before {
                                        opacity: 1;
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(2) {
                        .dropdown-menu {
                            width: 607px;
                            @include media-breakpoint-down(xl) {
                                width: 587px;
                            }
                        }
                    }
                    &:nth-child(3) {
                        .dropdown-menu {
                            width: 500px;
                            @include media-breakpoint-down(xl) {
                                width: 473px;
                            }
                        }
                    }
                    &:nth-child(4) {
                        .dropdown-menu {
                            width: 392px;
                            @include media-breakpoint-down(xl) {
                                width: 371px;
                            }
                        }
                    }
                    ul.dropdown-menu.show {
                        display: flex;
                        align-items: flex-start;
                    }

                    .nav-link {
                        padding: 0;
                        font-weight: 500;
                        font-size: 12px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        color: rgba(255,255,255, .85);
                        -ms-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                        position: relative;
                        margin-top: .3rem;
                        height: 6rem;
                        text-transform: uppercase;
                        @include media-breakpoint-down(lg) {
                            height: auto;
                            display: block;
                            width: 100%;
                            padding: 10px 0 10px;
                        }
                        @include media-breakpoint-down(md) {
                            height: auto;
                            padding: 10px 0 10px;
                            display: block;
                            width: 100%;
                        }
                        @include media-breakpoint-down(sm) {
                            height: auto;
                            padding: 10px 0 10px;
                            display: block;
                            width: 100%;
                        }

                        &:before {
                            position: absolute;
                            content: "";
                            width: 100%;
                            height: 4px;
                            background: $light;
                            left: 0;
                            width: 0;
                            bottom: 0;
                            opacity: 0;
                            -ms-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            -webkit-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                        }
                        strong {
                            display: block;
                            font-weight: 800!important;
                        }
                        &:hover {
                            color: $light;
                            &:before {
                                width: 100%;
                                opacity: 1;
                            }
                        }
                    }
                    .btn-donate {
                        border: 1px solid $light;
                        color: $light;
                        width: 15.2rem;
                        padding-left: 0;
                        padding-right: 0;
                        @include media-breakpoint-up(xxxl) {
                            width: 25.4rem;
                        }
                        &:before {
                            display: none;
                        }
                        i {
                            padding-left: 15px;
                        }
                        &:hover {
                            background-color: $light;
                            color: $dark1;
                        }
                    }
                    .social-ic {
                        font-size: 20px;
                        color: $light;
                        &:before {
                            display: none;
                        }
                    }
                    &.active {
                        a {
                            color: $light;
                            &:before {
                                width: 100%;
                                opacity: 1;
                            }
                        }
                        .dropdown-menu {
                            visibility: visible;
                            opacity: 1;
                        }
                        .dropdown-toggle {
                            color: $light;
                            &:before {
                                width: 100%;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            &:not(.show) {
                opacity: 0;
            }
        }
        &-search-form {
            padding-right: inherit;
            right: 4rem;
            width: 25.6rem;
            transition: opacity .1s ease-in, width .3s ease-in;
            z-index: 1;
            @media (max-width: 1350px) {
                padding-right: 0;
                right: 7rem;
            }
            &:not(.show) {
                opacity: 0;
                width: 0;
            }
            .form-control {
                color: currentColor;
                border-color: currentColor;
            }
            button {
                position: absolute;
                right: 1.3rem;
                top: 0;
                height: 100%;
                opacity: 0;
                width: 2.5rem;
                @media (max-width: 1350px) {
                    right: .5rem;
                }
            }
        }
        &-absolute-buttons {
            padding: inherit;
            top: .3rem;
        }
        &-search {
            color: currentColor;
            margin-left: 3rem;
            svg {
                width: 2.029rem;
                height: 1.788rem;
            }
        }
        &-toggler {
            color: currentColor;
            margin-left: 1.9rem;
            svg {
                width: 2.6rem;
                height: 1.8rem;
                fill: currentColor;
                box-sizing: content-box;
                padding: 3px;
                path {
                    transition: all .3s ease-in;
                }
            }
            &:not(.collapsed) {
                @include media-breakpoint-up(lg) {
                    svg {
                        path {
                            &:first-child {
                                opacity: 0;
                            }
                            &:nth-child(2) {
                                transform: rotate(45deg);
                                transform-origin: left top;
                            }
                            &:last-child {
                                transform: rotate(-45deg);
                                transform-origin: left bottom;
                            }
                        }
                    }
                }
            }
            &:not(.mobile-collapsed) {
                @include media-breakpoint-down(lg) {
                    svg {
                        path {
                            &:first-child {
                                opacity: 0;
                            }
                            &:nth-child(2) {
                                transform: rotate(45deg);
                                transform-origin: left top;
                            }
                            &:last-child {
                                transform: rotate(-45deg);
                                transform-origin: left bottom;
                            }
                        }
                    }
                }
            }
        }
    }

    .no-header-image & {
        color: $primary;
        @include media-breakpoint-up(lg) {
            margin-top: 4.2rem;
        }
        .navbar {
            position: relative;
            &-brand {
                color: $black;
            }
            &-collapse {
                border-color: rgba(0, 0, 0, .4);
                .navbar-nav {
                    li {
                        .dropdown-toggle {
                            color: $dark5;
                            &:hover {
                                color: $dark;
                            }
                        }
                        .social-ic {
                            color: $dark;
                        }
                        .nav-link {
                            color: $dark;
                            &:hover {
                                color: $dark;
                            }
                            &:before {
                                background-color: $dark;
                            }
                        }
                        .btn-donate {
                            border-color: $dark;
                            color: $dark;
                            &:hover {
                                background-color: $dark;
                                color: $light;
                            }
                        }
                        .dropdown-menu {
                            border-color: $dark5;
                            li {
                                .dropdown-item {
                                    color: $dark6;
                                    &:hover {
                                        color: $dark;
                                    }
                                    &:before {
                                        background-color: $dark;
                                    }
                                }
                                &.active {
                                    .dropdown-item {
                                        color: $dark;
                                    }
                                }
                            }
                        }
                    }
                    .active {
                        .dropdown-toggle {
                            color: $dark;
                        }
                    }
                }
            }
        }
    }

    .mobile-menu-open & {
        @include media-breakpoint-down(lg) {
            color: $black;
        }
        .navbar-brand {
            @include media-breakpoint-down(lg) {
                color: $black!important;
            }
        }
    }
}

.mobile-header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    width: 100%;
    display: none;
    align-items: center;
    @include media-breakpoint-down(lg) {
        display: block;
    }
    @include media-breakpoint-down(md) {
        display: block;
    }
    @include media-breakpoint-down(sm) {
        display: block;
    }
    .header-block {
        padding: 60px 30px 15px;
        position: relative;
        .mobile-logo {
            display: block;
            color: $white;
            svg {
                width: 91px;
                height: 41px;
            }
        }
        ul {
            position: absolute;
            right: 30px;
            top: 60px;
            margin: 0;
            li {
                display: inline-block;
                margin-right: 20px;
                &:last-child {
                    margin: 0;
                }
                a {
                    font-size: 20px;
                    color: $light;
                }
            }
        }
    }

    .no-header-image & {
        .header-block {
            .mobile-logo {
                color: $black;
            }
        }
    }
}

body.mobile-menu-open {
    @include media-breakpoint-down(lg) {
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
.navbar-mbl-wrp {
    background-color: $light;
    height: 100%;
    width: 100%;
    position: fixed;
    right: -100%;
    top: 0;
    bottom: 0;
    overflow: auto;
    z-index: 3;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    padding: 20.4rem 3rem 0;

    .header-block {
        position: relative;
        padding: 0 0 90px;
        img {
            width: 91px;
        }
        ul {
            position: absolute;
            right: 0;
            top: 5px;
            margin: 0;
            li {
                display: inline-block;
                margin-right: 20px;
                &:last-child {
                    margin: 0;
                }
                a {
                    font-size: 20px;
                    color: $dark1;
                }
            }
        }
    }

    .accordion {
        padding: 0;
        border-radius: 0;
        border: none;
        margin: 0;
        .accordion-item {
            border-radius: 0;
            background-color: transparent;
            border: none;
            .accordion-header {
                margin-bottom: 4rem;
            }
            .accordion-button {
                background: transparent;
                border: none;
                padding: 0 0 3rem 0;
                text-align: center;
                display: block;
                width: 100%;
                font-weight: normal;
                font-size: 18px;
                letter-spacing: 0.02em;
                text-align: center;
                color: $dark;
                text-transform: uppercase;
                box-shadow: none;
                overflow: none;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
                strong {
                    display: block;
                    margin: 0 0 5px;
                }
                &:after {
                    display: none;
                }
            }

            .accordion-body {
                padding: auto;
                ul {
                    li {
                        margin: 0 0 40px;
                        a {
                            display: block;
                            width: 100%;
                            font-weight: normal;
                            font-size: 18px;
                            letter-spacing: 0.02em;
                            text-align: center;
                            color: $dark;
                            text-transform: uppercase;
                            opacity: 50%;
                            -webkit-transition: all 0.3s linear;
                            transition: all 0.3s linear;
                            strong {
                                display: block;
                                margin: 0 0 5px;
                            }
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .copyright {
        background: $light;
        padding: 15px 0;
        p {
            margin: 0 auto;
            display: table;
            width: 237px;
            font-weight: normal;
            font-size: 12px;
            letter-spacing: 0.02em;
            line-height: 14px;
            text-align: left;
            color: $dark;
        }
    }
}
.navbar-mbl-wrp.expand-menu-open {
    right: 0;
}

.inner-mobile-header {
    position: relative;
    .header-block {
        ul {
            li {
                a {
                    color: $dark;
                }
            }
        }
    }
}
