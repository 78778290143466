@charset "UTF-8";

@import "globals/fonts";
@import "globals/variables";



.clearfix{
    clear: both;
}

@import "vendors/bootstrap/configuration";

@import "globals/utilities";

@import "vendors/bootstrap/bootstrap";

@import "vendors/plyr";

@import "vendors/event-calendar";


@import "globals/typography";
@import "globals/work-badges";
@import "globals/form";
@import "globals/alert";
@import "globals/swipers";

@import "component";

/* **** Index Page css **** */
@import "component/index/hero";

/* **** End Index Page css **** */


/* ****  register Page Css **** */
@import "component/register/register";
/* ****  End register Page Css **** */


/* **** About Page **** */
@import "component/about/about-hero";
/* **** End About Page Css **** */


/* **** News PAge Css **** */
@import "component/news/news";
/* **** End News PAge Css **** */


/* **** Approval Page **** */
@import "component/approval/approval-hero";
@import "component/approval/approval-slider";
/* **** End Approval Page **** */

html{
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body{
    background-color: $light;
    color: $dark1;
    padding: 0;
    margin: 0;
    $font-family-base : 'Proxima Nova';
}
ul{
    padding: 0;
}
li{
    list-style: none;
}
a{
    text-decoration: none;
}



.container{
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-down(xxl) { 
        padding-left: 6rem;
        padding-right: 6rem;
    }
    @include media-breakpoint-down(xl) { 
        padding-left: 3rem;
        padding-right: 3rem;
    }
}



.titlebar{
    h2{
        font-size: 52px;
        line-height: 52px;
        text-align: left;
        color: $dark;   
        text-transform: uppercase;
        font-weight: 300;
        margin: 0;
        @include media-breakpoint-down(lg) { 
            font-size: 30px;
            line-height: 30px;;
        }
        @include media-breakpoint-down(md) { 
             font-size: 30px;
             line-height: 30px;;
        }
    
        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 30px;;
        }
        strong{
            font-weight: bold;
            display: block;
        }

    }
}


.container-right {
    margin-right: 0;
    margin-left: calc(50vw - 608px);
    @media (min-width: 1601px) and (max-width:1800px) {
        margin-left: calc(50vw - 608px);
    }
    @media (min-width: 1401px) and (max-width:1600px) {
        margin-left: calc(50vw - 608px);
    }
    @media (min-width: 1301px) and (max-width:1400px) {
        margin-left: calc(50vw - 608px);
    }
    @media (min-width: 1200px) and (max-width:1350px) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 0;
    }
    @include media-breakpoint-down(xl) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 0;
    }
    @include media-breakpoint-down(lg) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 0;
    }
    @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 0;
    }
    @include media-breakpoint-down(sm) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 0;
    }
}




.expand-btn{
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    color: $black;
    border-top: 2px solid $black;
    padding-top: 10px;
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
    display: none;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    &:focus{
        box-shadow: none;
        color: $dark1;
    }
    @include media-breakpoint-down(md) {
       display: block;
    }
}




.about-details{
    .collapse{
        display: block;
        @include media-breakpoint-down(md) {
           display: none;
         }
         @include media-breakpoint-down(sm) {
           display: none;
         }
    }
    .collapse.show{
        display: block;
        @include media-breakpoint-down(md) {
           display: block;
         }
         @include media-breakpoint-down(sm) {
           display: block;
         }
    }
}


.work-details{
    .collapse{
        display: block;
        @include media-breakpoint-down(md) {
           display: none;
         }
         @include media-breakpoint-down(sm) {
           display: none;
         }
    }
    .collapse.show{
        display: block;
        @include media-breakpoint-down(md) {
           display: block;
         }
         @include media-breakpoint-down(sm) {
           display: block;
         }
    }
}


.gallery-wrp{
    .col-md-4.col-sm-12{
        padding-left: 8px;
        padding-right: 8px;
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .collapse{
        display: block;
        @include media-breakpoint-down(sm) {
           display: none;
        }
        .row{
            .col-md-4.col-sm-6{
                @include media-breakpoint-down(sm) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
    .collapse.show{
        display: block;
        @include media-breakpoint-down(sm) {
           display: block;
        }
    }
}


.creative-team-wrp{
    @include media-breakpoint-down(md) {
        .creative-box1{
            display: block;
        }
        .creative-box2{
            display: none;
        }
        .creative-box3{
            display: block;
            margin: 60px 0 0;
        }
        .creative-box4{
            display: block;
            margin: 60px 0 0;
        }
        .creative-box5{
            display: block;
            margin: 60px 0 0;
        }
     }
    @include media-breakpoint-down(sm) {
        .creative-box1{
            display: block;
        }
        .creative-box2{
            display: none;
        }
        .creative-box3{
            display: block;
            margin: 60px 0 0;
        }
        .creative-box4{
            display: block;
            margin: 60px 0 0;
        }
        .creative-box5{
            display: block;
            margin: 60px 0 0;
        }
     }
}





.error-input{
    position: relative;
    label{
        color: $red !important;
    }
    span{
        font-family: "Proxima Nova";
        font-weight: normal;
        font-style: italic;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: $red;
        position: absolute;
        right: 0;
        top: 0;
    }
}



.mobile-done{
    @include media-breakpoint-down(md) {
        display: none;
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
}
.mobile-block{
    display: none;
    margin: 40px 0 0;
    @include media-breakpoint-down(md) {
        display: block;
    }
    @include media-breakpoint-down(sm) {
        display: block;
    }
}