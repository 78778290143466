.section-audibles {
    .swiper {
        .slider-img.audible-thumb {
            &.placeholder {
                height: 0;
                padding-bottom: 100%;
                background: url(../images/rnz-square.jpg) no-repeat center center;
                background-size: cover;
                &:after {
                    opacity: .2;
                }
                .gallery-item {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    cursor: pointer;
                }
            }
        }
    }
    .modal-body {
        padding: 30px 83px;
        max-width: 100rem;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        .player {
            margin-bottom: 2rem;
        }
    }
}