.section-resources {
    .swiper {
        .swiper-wrapper {
            .slider-img {
                &.placeholder {
                    height: 0;
                    padding-bottom: (540/401)*100%;
                }
            }
        }
    }
}