.section-short-films, .section-webisodes {
    .meta {
        height: 2.5rem;
        font-style: italic;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .excerpt {
        margin-bottom: 2rem;
        a {
            text-decoration: underline;
        }
    }

    .modal-body {
        .plyr--video {
            max-width: 80%;
            max-height: 80%;
            margin: 0 auto 2rem;
        }
        .video-description {
            text-align: center;
            margin-top: 1.5rem;
            max-height: 8rem;
            overflow-y: scroll;
        }
        .swiper {
            padding: 0;
            overflow: hidden;
            position: static;
        }
        .swiper-button-prev, .swiper-button-next {
            color: $white;
            top: -3rem;
            @include media-breakpoint-up(sm) {
                top: 45%;
            }
        }
        .swiper-button-prev {
            left: 0;
            right: auto;
            @include media-breakpoint-up(sm) {
                left: -3rem;
            }
        }
        .swiper-button-next {
            left: 6rem;
            right: auto;
            @include media-breakpoint-up(sm) {
                right: -3rem;
                left: auto;
            }
        }
    }
}