.mini-work-section {
    margin-bottom: 4.5rem;
    @include media-breakpoint-up(lg) {
        margin-bottom: 12.4rem;
    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 17rem;
    }
    .section-heading {
        .work-badges {
            margin-left: 1.2rem;
        }
    }

    .gallery-img {
        width: 100%;
        overflow: hidden;
        .gallery-item {
            object-fit: cover;
            object-position: center center;
        }   
        .svg-icon-play {
            position: absolute;
            width: 3.4rem;
            height: 3.4rem;
            cursor: pointer;
            bottom: 2.5rem;
            left: 2.5rem;
            fill: $white;
        }
    }

    .mini-work-video-toggler {
        .svg-icon-play {
            width: 5.8rem;
            height: 5.7rem;
            margin-right: 15px;
        }
    }

    .digital-scrapbook-gallery {
        .grid-item {
            width: calc((100% - 30px)/3);
            @include media-breakpoint-down(md) {
                width: calc((100% - 15px)/2);
            }
            &--width2 {
                width: calc((100% - 30px)/3*2);
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
            .embed-container {
                position: relative; 
                padding-bottom: 56.25%; 
                padding-top: 30px; 
                height: 0; 
                overflow: hidden; 
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}