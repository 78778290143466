@font-face {
    font-family: 'Arapey';
    src: url('../fonts/Arapey-Regular.woff2') format('woff2'),
        url('../fonts/Arapey-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arapey';
    src: url('../fonts/Arapey-Italic.woff2') format('woff2'),
        url('../fonts/Arapey-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}