.modal-header {
    padding: 1.5rem;
    .btn-close {
        color: currentColor;
        opacity: 1;
        background-image: url(escape-svg("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#FFF'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>"));
    }
}

.section-gallery {
    .gallery-img {
        width: 100%;
        height: 0;
        padding-bottom: 55%;
        overflow: hidden;
        .gallery-item {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: auto;
            cursor: pointer;
        }   
        .svg-icon-play {
            position: absolute;
            width: 3.4rem;
            height: 3.4rem;
            cursor: pointer;
            bottom: 2.5rem;
            left: 2.5rem;
            fill: $white;
        }
    }

    .modal-body {
        .plyr--video {
            max-width: 80%;
            max-height: 80%;
            margin: 0 auto 2rem;
        }
        .video-description {
            text-align: center;
            margin-top: 1.5rem;
            max-height: 8rem;
            overflow-y: scroll;
        }
        .swiper {
            padding: 0;
            overflow: hidden;
            position: static;
        }
        .swiper-button-prev, .swiper-button-next {
            color: $white;
            top: -3rem;
            @include media-breakpoint-up(sm) {
                top: 45%;
            }
        }
        .swiper-button-prev {
            left: 0;
            right: auto;
            @include media-breakpoint-up(sm) {
                left: -3rem;
            }
        }
        .swiper-button-next {
            left: 6rem;
            right: auto;
            @include media-breakpoint-up(sm) {
                right: -3rem;
                left: auto;
            }
        }
        .gallery-img {
            width: auto;
            padding-bottom: 0;
            height: calc(100vh - 9rem);
            margin-bottom: 0;
            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }
}