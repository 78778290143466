.work-badges {
    font-size: 0;
    li {
        display: inline-block;
        border-radius: 13px;
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 1.2rem;
        text-align: center;
        color: $light;
        margin-right: .5rem;
        padding: .1rem 1.2rem 0;
        margin-bottom: .5rem;
        &:last-child {
            margin: 0;
        }
    }
}