.about-details {
    padding: 0 0 160px;
    p {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: $dark;
        margin: 0 0 15px;
    }
    .expand-btn{
        margin: 40px 0 0;
    }
}

.about-title {
    padding: 0 0 150px;
    h2 {
        font-weight: normal;
        font-style: italic;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        color: $dark;
        margin: 0 0 30px;
        text-transform: uppercase;
        font-family: $arapey;

        @include media-breakpoint-down(md) {
            font-size: 28px;
            line-height: 34px;
            br {
                display: none;
            }
        }
        @include media-breakpoint-down(sm) {
            font-size: 24px;
            line-height: 32px;
            br {
                display: none;
            }
        }
    }
    p {
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.02em;
        text-align: center;
        color: $dark;
        strong {
            display: block;
        }
    }
}
