.hero.work {

}



.work-hero {
    height: 60rem;
    background-color: #6E6E6E;
    display: flex;
    align-items: flex-end;
    position: relative;
    margin: 0 0 80px;
    @include media-breakpoint-down(md) {
        height: 500px;
        background-position: 38%;
        margin: 0 0 40px;
    }
    @include media-breakpoint-down(sm) {
        height: 500px;
        background-position: 38%;
        margin: 0 0 40px;
    }
    picture {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .hero-image {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 0;
    }
    .back-button {
        position: absolute;
        top: 170px;
    }
    .about-dt {
        position: absolute;
        bottom: 65px;
        h2 {
            font-weight: normal;
            font-size: 52px;
            line-height: 60px;
            text-align: left;
            color: $light;
            margin: 0;
            @include media-breakpoint-down(md) {
                font-size: 30px;
                line-height: 38px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 28px;
                line-height: 35px;
            }
            strong {
                display: block;
            }
        }
    }
}

.work-details {
    padding: 0 0 15.3rem;
    @include media-breakpoint-up(md) {
        padding: 0;
    }
    .work-info {
        height: 100%;
        .title {
            font-size: 6rem;
            line-height: 5.2rem;
            font-weight: 700;
            margin-bottom: 4rem;
            @include media-breakpoint-down(md) {
                font-size: 4.4rem;
                line-height: 3.6rem;
                margin-bottom: 2.2rem;
            }
        }
        .subtitle {
            width: 200px;
            font-weight: bold;
            font-size: 1.6rem;
            letter-spacing: 0.02em;
            line-height: 20px;
            text-align: left;
            color: $dark;
            margin: 0 0 40px;
        }
        .svg-icon-play {
            width: 5.8rem;
            height: 5.7rem;
            margin-right: 15px;
        }
    }
    
    .button-block {
        display: flex;
        align-items: center;
        padding: 0;
        li {
            display: inline-block;
            margin: 0 15px 0 0;
            &:last-child {
                margin: 0;
            }
            img{
                max-width: 100%;
            }
        }
    }

    .work-hero-video-toggler {
        bottom: 7.5rem;
    }
    

    .social-ic {
        position: absolute;
        right: 3rem;
        @include media-breakpoint-up(md) {
            left: 0;
            right: auto;
        }
        a {
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            border: 1px solid $dark;
            color: $dark;
            font-size: 12px;
            border-radius: 100%;
            display: inline-block;
            text-align: center;
            line-height: 25px;
            -webkit-transition: all 0.3s linear;
            transition: all 0.3s linear;
            &:last-child {
                border-radius: 4px;
            }
            &:hover {
                color: $light;
                background-color: $dark;
            }
        }
    }
    
    .mobile-show{
        display: none;
        @include media-breakpoint-down(md) {
            display: block;
        }
        @include media-breakpoint-down(sm) {
            display: block;
        }
        .social-ic{
            @include media-breakpoint-down(md) {
                position: relative;
                bottom: 0;
                left: 0;
                margin: 100px 0 0 auto;
                display: table;
            }
            @include media-breakpoint-down(sm) {
                position: relative;
                bottom: 0;
                left: 0;
                margin: 100px 0 0 auto;
                display: table;
            }
        }
    }
    p {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: $dark;
        margin: 0 0 15px;
        span{
            font-style: italic;
            font-weight: 500;
        }

        a:not(.btn) {
            text-decoration: underline;
        }
    }
    
}

.address-wrp {
    padding: 0 0 90px;
    @include media-breakpoint-down(md) {
        padding: 0;
    }
    @include media-breakpoint-down(sm) {
        padding: 0;
    }
    .address-box {
        @include media-breakpoint-down(md) {
            margin: 0 0 80px;
        }
        @include media-breakpoint-down(sm) {
            margin: 0 0 80px;
        }
        
    
        span {
            font-weight: 300;
            font-size: 14px;
            letter-spacing: 0.02em;
            line-height: 16px;
            text-align: left;
            color: $dark;
            display: block;
            margin: 0 0 10px;
        }
        p {
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.02em;
            line-height: 18px;
            text-align: left;
            display: block;
            margin: 0 0 30px;
        }
        ul {
            display: flex;
            align-items: center;
            li {
                font-weight: 300;
                font-size: 12px;
                letter-spacing: 0.02em;
                text-align: left;
                color: $dark;
                display: inline-block;
                margin: 0 25px 0 0;
            }
        }
    }
}

.gallery-wrp {
    .col-md-4.col-sm-6{
        padding-left: 8px;
        padding-right: 8px;
    }
    .gallery-img {
        position: relative;
        margin: 0 0 10px;
        a {
            img {
                max-width: 100%;
                position: absolute;
                left: 15px;
                bottom: 15px;
                width: auto;
            }
        }
        img {
            max-width: 100%;
            width: 100%;
        }
    }
    .expand-btn{
        margin: 40px 0 0;
    }
}

.creative-team-wrp {
    padding: 0 0 150px;
    @include media-breakpoint-down(md) {
        padding:  0 0 80px;
    }
    @include media-breakpoint-down(sm) {
        padding:  0 0 80px;
    }
    .col-md-3.col-sm-6{
        @include media-breakpoint-down(md) {
            margin: 60px 0 15px;
            &:first-child{
                margin: 0 0 15px ;
            }
        }
    }
   
    h3 {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        color: $dark;
        margin: 0 0 15px;
        @include media-breakpoint-down(md) {
            // margin: 40px 0 15px;
        }
        @include media-breakpoint-down(sm) {
            // margin: 40px 0 15px;
        }
    }
    ul {
        li {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: left;
            color: $dark;
        }
    }
    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        color: $dark;
        margin: 0 0 10px;
    }

    .expand-btn{
        margin: 40px 0 0;
    }

    .title{
        h2{
            font-weight:500;
        }
    }
}

.work-slider-wrpper {
    position: relative;
    padding: 0 0 150px;
    @include media-breakpoint-down(md) {
        padding:  0 0 80px;
    }
    @include media-breakpoint-down(sm) {
        padding:  0 0 80px;
    }


    .title-container {
        position: relative;
        .titlebar {
            border-bottom: 1px solid $dark8;
            padding-bottom: 15px;
            margin: 0 0 40px;
            h2{
                font-weight: bold;
                font-size: 14px;
                letter-spacing: 0.02em;
                line-height: 14px;
                text-align: left;
                color: $dark;
                opacity: 1;
                text-transform: uppercase;
                margin: 0;
                display: block;
                width: 100%;
            }
        }
        .mySwiperthree {
            padding: 0;
            .swiper-wrapper {
                .slider-img {
                    position: relative;
                    margin: 0 0 9px;
                    img {
                        width: 100%;
                    }
                    .play-img {
                        max-width: 100%;
                        position: absolute;
                        left: 15px;
                        bottom: 15px;
                        width: auto;
                    }
                }
                .slider-details {
                    h2 {
                        font-weight: bold;
                        font-size: 32px;
                        line-height: 36px;
                        text-align: left;
                        color: $dark;
                        margin: 0 0 40px;
                        text-transform: uppercase;
                        @include media-breakpoint-down(xl) {
                            font-size: 26px;
                            line-height: 30px;
                            br {
                                display: none;
                            }
                        }
                        @include media-breakpoint-down(lg) {
                            font-size: 26px;
                            line-height: 30px;
                            br {
                                display: none;
                            }
                        }
                        @include media-breakpoint-down(md) {
                            font-size: 26px;
                            line-height: 30px;
                            br {
                                display: none;
                            }
                        }
                        @include media-breakpoint-down(sm) {
                            font-size: 26px;
                            line-height: 30px;
                            br {
                                display: none;
                            }
                        }
                    }
                    p {
                        font-weight: normal;
                        font-size: 12px;
                        letter-spacing: 0.02em;
                        text-align: left;
                        color: $dark;
                        margin: 0 0 50px;
                    }
                    a {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 14px;
                        text-align: left;
                        color: $dark;
                        border-top: 2px solid $dark;
                        padding-top: 10px;
                        display: inline-block;
                        margin-right: 15px;
                        vertical-align: middle;
                    }
                }
            }
            
        }
    }
}