.checkout, .payment-success {
    .product-image {
        width: auto;
        @include media-breakpoint-up(md) {
            width: 13.9rem;
        }
        @include media-breakpoint-up(lg) {
            width: auto;
        }
    }
    .order-total-amount {
        display: inline-block;
        text-align: center;
        label {
            text-transform: uppercase;
            font-size: 1.5rem;
            font-weight: 500;
            display: block;
            margin-bottom: 1rem;
        }
        strong {
            font-size: 1.8rem;
            display: block;
            &:after {
                content: "";
                background-color: $black;
                height: 4px;
                width: 100%;
                display: block;
                margin-top: 1rem;
            }
        }
    }
}

.payment-success {
    .order-total-amount {
        label {
            color: $success;
            font-weight: 700;
        }
        strong {
            &:after {
                background-color: $success;
            }
        }
    }
    .success-message {
        max-width: 43.6rem;
        p {
            margin: 0;
            font-size: 2.8rem;
            line-height: 3rem;
        }
    }
    
    .svg-icon-play {
        width: 5.8rem;
        height: 5.7rem;
        margin-right: 15px;
    }
}