.hero {
    .meta {
        font-family: "Arapey";
        font-weight: normal;
        font-style: italic;
        font-size: 1.6rem;
        line-height: 1.4rem;
    }
    &-top {
        display: flex;
        align-items: flex-end;
        position: relative;
        margin: 0;
        min-height: 24.7rem;
        margin-bottom: 4.64rem;
        @include media-breakpoint-down(lg) {
            min-height: 27.6rem;
        }
        .page-title {
            font-weight: 300;
            font-size: 5.2rem;
            line-height: .9;
            text-align: left;
            margin: 0;
            text-transform: uppercase;
            color: #212529;
            @include media-breakpoint-down(lg) {
                font-size: 3rem;
                line-height: 3.8rem;
            }
            @include media-breakpoint-down(sm) {
                font-size: 28px;
                line-height: 35px;
            }
        }
    }

    &-bottom {
        position: relative;
        .page-title {
            font-weight: 700;
            font-size: 6rem;
            line-height: 5.2rem;
            @include media-breakpoint-down(md) {
                font-size: 4.4rem;
                line-height: 3.6rem;
                margin-bottom: 2.2rem;
            }
        }
        .social-ic {
            position: absolute;
            bottom: 15px;
            a {
                height: 25px;
                width: 25px;
                display: flex;
                align-items: center;
                border: 1px solid $dark;
                color: $dark;
                font-size: 12px;
                margin: 0 10px 0 0;
                border-radius: 100%;
                display: inline-block;
                text-align: center;
                line-height: 25px;
                -webkit-transition: all 0.3s linear;
                transition: all 0.3s linear;
                &:hover {
                    color: $light;
                    background-color: $dark;
                }
            }
        }
    }

    .has-header-image & {
        .hero-top {
            height: 60rem;
            margin: 0 0 80px;
            position: relative;
            @include media-breakpoint-down(lg) {
                height: 50rem;
                background-position: 38%;
                margin: 0 0 40px;
            }
            picture {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
            .hero-image {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
            .back-button {
                position: absolute;
                top: 170px;
                z-index: 2;
            }
            .page-title {
                position: absolute;
                bottom: 65px;
                color: $white;
                z-index: 2;
            }
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 1;
        background-color: $black;
        opacity: 0;
    }

    .lead-paragraphs {
        a:not(.btn) {
            text-decoration: underline;
        }
        .btn {
            min-width: 28.7rem;
            margin-top: 4.5rem;
            margin-bottom: 4.5rem;
        }
        p + .btn {
            margin-top: 2.5rem;
        }
    }

    .lead-paragraphs.read-more-container {
        @include media-breakpoint-down(md) {
            position: relative;
            max-height: 12rem;
            overflow: hidden;
            margin-bottom: 20px;
            transition: max-height 0.15s ease-out;
            &:after {
                position: absolute;
                background-image: linear-gradient(to bottom, transparent, rgba($white, .9));
                width: 100%;
                height: 6rem;
                z-index: 1;
                content: "";
                display: block;
                bottom: 0;
                left: 0;
            }
            &.expanded {
                max-height: 100%;
                &:after {
                    display: none;
                }
            }
            .expand-btn {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: auto;
                cursor: pointer;
                z-index: 2;
            }
        }
    }
}


@import "hero/page";
@import "hero/work";
@import "hero/news-article";
@import "hero/member";
@import "hero/search";