.hero {
    &.page {
    }
    .no-header-image &.page {
        .hero-top {
            .page-title {
                padding-top: 3rem;
                padding-bottom: 3rem;
                border-bottom: 1px solid rgba(0,0,0,.4);
            }
        }
    }
    .my-nzdc.register &.page, .my-nzdc.profile &.page, .my-nzdc.dashboard &.page, .my-nzdc.checkout &.page {
        .hero-top {
            .page-title {
                padding-bottom: 0;
                border-bottom: none;
            }
        }
    }
}