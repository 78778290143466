.register-wrp {
    padding: 50px 0 0;
    .offset-1{
        @include media-breakpoint-down(lg) {
            margin: 0;
        }
        @include media-breakpoint-down(md) {
            margin: 0;
        }
        @include media-breakpoint-down(sm) {
            margin: 0;
        }
    }
    .back-arrow {
        display: block;
        img {
            max-width: 100%;
            margin: 0 0 35px;
        }
    }

    .register-block {
        padding: 0 0 130px;
        @include media-breakpoint-down(md) {
            padding: 0 0 60px;
        }
        .titlebox {
            border-bottom: 1px solid $dark6;
            padding: 0 0 10px;
            margin: 0 0 60px;

            @include media-breakpoint-down(lg) {
                display: block;
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
            h3 {
                font-weight: 700;
                font-size: 14px;
                line-height: 14px;
                text-align: left;
                color: $dark;
                margin: 0;
            }
        }
        .mobile-title {
            display: none;
            @include media-breakpoint-down(md) {
                display: block;
                margin: 40px 0 15px;
            }
            @include media-breakpoint-down(sm) {
                display: block;
                margin: 40px 0 15px;
            }
        }

        .register-user-info {
            display: flex;
            @include media-breakpoint-down(lg) {
                width: 100%;
                display: block;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
                display: flex;
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                display: block;
            }
            .img-info {
                min-width:186px;
                width:186px;
                margin-right: 15px;
                height: 250px;
                background: #A3A3A3;
                @include media-breakpoint-down(lg) {
                    margin: 0 0 12px;
                }
                @include media-breakpoint-down(md) {
                    margin: 0 15px 0 0;
                }
                @include media-breakpoint-down(sm) {
                    margin: 0 0 12px;
                }
            }
            span {
                font-weight: normal;
                font-style: italic;
                font-size: 16px;
                line-height: 14px;
                text-align: left;
                color: $dark;
            }
            h2 {
                font-weight: bold;
                font-size: 32px;
                line-height: 32px;
                text-align: left;
                color: $dark;
                text-transform: uppercase;
            }
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                text-align: left;
                color: $dark;
                width: 80%;
                margin: 0 0 31px;
            }
            .new-style{
                font-family: 'ProximaNova';
            }
            span{
                font-weight: normal;
                font-style: normal;
                font-size: 14px;
                line-height: 16px;
                text-align: left;
                color: $dark1;
                display: table;
                text-align: center;
                margin: 40px  0 0;
                strong{
                    margin: 8px 0 0;
                    display: block;
                    font-size: 18px;
                    font-weight: bold;
                    padding-bottom: 5px;
                    border-bottom: 4px solid $dark1;
                }
            }
        }
        form {
            max-width: 48.5rem;
            .titlebox {
                display: none;
                @include media-breakpoint-down(md) {
                    display: block;
                    margin: 40px 0 15px;
                }
                @include media-breakpoint-down(sm) {
                    display: block;
                    margin: 40px 0 15px;
                }
            }
            .form-group {
                margin: 0 0 15px;
                label {
                    font-weight: 500;
                    font-size: 12px;
                    letter-spacing: 0.02em;
                    text-align: left;
                    color: $dark1;
                    margin: 0 0 10px;
                    display: block;
                }
                .form-control {
                    outline: none;
                    box-shadow: none;
                    border-radius: 0;
                    border: none;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 14px;
                    text-align: left;
                    
                    height: auto;
                    padding: 0 0 10px;
                    border-bottom: 1px solid $dark8;
                    &:focus {
                        border-color: $dark;
                    }
                }
               
            }
           
            .select-box{
                margin: 0 0 60px;
                select.form-control {
                    width: 100%;
                    display: inline-block;
                    margin: 0 0;
                    -webkit-appearance: none;
                    background-image: url(../images/down-arrow.svg);
                    background-repeat: no-repeat;
                    background-size: 10px;
                    background-position: center right 15px;
                }
            }

            .redio-box{
                margin: 0 0 40px;
                ul{
                    margin: 0;
                    li{
                        float: none;
                        display: inline-block;
                        margin: 0 40px 0 0;
                        width: auto;
                        padding: 0;;
                        [type="radio"]:checked,
                        [type="radio"]:not(:checked) {
                            position: absolute;
                            left: -9999px;
                        }
                        [type="radio"]:checked + label,
                        [type="radio"]:not(:checked) + label
                        {
                            position: relative;
                            padding-left: 45px;
                            display: inline-block;
                            cursor: pointer;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 34px;
                            text-align: left;
                            color: $dark1;

                        }
                        [type="radio"]:checked + label:before,
                        [type="radio"]:not(:checked) + label:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 35px;
                            height: 35px;
                            border: 1px solid #ddd;
                            border-radius: 100%;
                            background: #fff;
                        }
                        [type="radio"]:checked + label:after,
                        [type="radio"]:not(:checked) + label:after {
                            content: '';
                            width: 21px;
                            height: 21px;
                            background: $dark1;
                            position: absolute;
                            top: 7px;
                            left:7px;
                            border-radius: 100%;
                            -webkit-transition: all 0.2s ease;
                            transition: all 0.2s ease;
                        }
                        [type="radio"]:not(:checked) + label:after {
                            opacity: 0;
                            -webkit-transform: scale(0);
                            transform: scale(0);
                        }
                        [type="radio"]:checked + label:after {
                            opacity: 1;
                            -webkit-transform: scale(1);
                            transform: scale(1);
                        }
                    }
                }
            }
            
           
        }
        p{
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: $dark1;
            margin: 40px 0 50px;
        } 
        .cancel-btn{
            opacity: .5;
        }
    }

    .suscribe-wrpper{
        padding: 10px 0 70px;
    }
}




.successful-box{
    h4{
        font-weight: 500;
        font-size: 28px;
        line-height: 30px;
        text-align: left;
        color:$dark1;
        margin: 0 0 30px;
        strong{
            display: block;
        }

    }
}


.successful-block{
    h4{
        font-weight: 500;
        font-size: 28px;
        line-height: 30px;
        text-align: left;
        color:$dark1;
        margin: 0 0 50px;
        @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 26px;
        }
        @include media-breakpoint-down(sm) {
            font-size: 18px;
            line-height: 26px;
        }
        strong{
            display: block;
            @include media-breakpoint-down(md) {
                font-size: 22px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 22px;
            }
        }
    }

    p{
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: $dark1;
        margin: 50px 0 0;
        strong{
            font-weight:bold;
        }
    }
    ul{ 
        li{
            float: left;
            width: 50%;
           
            &:first-child{
                padding-right: 10px;
                @include media-breakpoint-down(lg) {
                    padding: 0;
                }
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }
            &:last-child{
                padding-left: 10px;
                @include media-breakpoint-down(lg) {
                    padding: 0;
                }
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }
            @include media-breakpoint-down(lg) {
                width: 100%;
                margin: 0 0 10px;
                padding: 0;
                &:last-child{
                    margin: 0;
                }
            }
            @include media-breakpoint-down(md) {
                width: 100%;
                margin: 0 0 10px;
                padding: 0;
                &:last-child{
                    margin: 0;
                }
            }
            @include media-breakpoint-down(sm) {
                width: 100%;
                margin: 0 0 10px;
                padding: 0;
                &:last-child{
                    margin: 0;
                }
            }
            a{
                display: block;
                width: 100%;
                text-align: center;
            }
            .cancel-btn{
                opacity: .5;
            }
        }
    }
}


.register-wrp {
    .register-block {
        .successful-register{
            span{
                width: 95px;
                text-transform: uppercase;
                font-weight: bold;
                color: #16B761;
                strong{
                    color: $dark1;
                    border-color: #16B761;
                }
            }
        }
    }
}
