.hero.search {
    .hero-top {
        min-height: 21.1rem;
        @include media-breakpoint-up(lg) {
            align-items: center;
        }
        .search-form {
            max-width: 54rem;
            position: relative;
            .form-control {
                font-size: 1.8rem;
                font-weight: 500;
            }
            button {
                color: $black;
                background: none;
                border: none;
                position: absolute;
                right: .8rem;
                top: .5rem;
                svg {
                    width: 2.311rem;
                    height: 2.036rem;
                }
            }
        }
    }
}
.search-results {
    .result {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 3rem;
    }
}