.newsletter-wrp {
    padding: 100px 0 0;
    @include media-breakpoint-down(xl) {
        padding: 80px 0;
    }
    @include media-breakpoint-down(lg) {
        padding: 20px 0 0;
    }
    @include media-breakpoint-down(md) {
        padding: 20px 0 0;
    }
    @include media-breakpoint-down(sm) {
        padding: 20px 0 0;
    }
    .newsletter-block {
        padding: 130px 0 110px;
        border-top: 1px solid $dark4;
        @include media-breakpoint-down(xl) {
            padding: 80px 0;
        }
        @include media-breakpoint-down(lg) {
            padding: 50px 0;
        }
        @include media-breakpoint-down(md) {
            padding: 50px 0;
        }
        @include media-breakpoint-down(sm) {
            padding: 50px 0;
        }
        h2 {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 14px;
            line-height: 14px;
            color: $dark;
            margin: 0 0 20px;
        }
        .form-group {
            margin: 0 auto 20px;
            width: 40%;
            @include media-breakpoint-down(xl) {
                width: 50%;
            }
            @include media-breakpoint-down(lg) {
                width: 80%;
                margin: 0 auto 15px;
            }
            @include media-breakpoint-down(md) {
                width: 80%;
                margin: 0 auto 15px;
            }
            @include media-breakpoint-down(sm) {
                width: 80%;
                margin: 0 auto 15px;
            }
            .form-control {
                height: auto;
                padding: 10px 15px;
                text-align: center;
                border: none;
                box-shadow: none;
                outline: none;
                border-radius: 0;
                border-bottom: 1px solid $dark8;

                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0.02em;
                color: $dark4;

                &:focus {
                    border-color: $dark;
                }
            }
        }
    }
}
