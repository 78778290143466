.about-header{
    padding-bottom: 0;
    .header-block{
        .mbl-logo{
            border-bottom: 1px solid $light;
            padding-bottom: 15px;
        }
    }
}

.approval-details {
    padding: 0 0 160px;
    @include media-breakpoint-down(xl) {
        padding: 0 0 110px;
    }
    @include media-breakpoint-down(lg) {
        padding: 0 0 110px;
    }
    @include media-breakpoint-down(md) {
        padding: 0 0 110px;
    }
    @include media-breakpoint-down(sm) {
        padding: 0 0 110px;
    }
    span {
        font-weight: normal;
        font-style: italic;
        font-size: 16px;
        line-height: 14px;
        text-align: left;
        color: $dark;
        display: block;
        margin: 0 0 10px;
        font-family: $arapey;
    }
    h3 {
        font-family: "Proxima Nova";
        font-weight: bold;
        font-size: 32px;
        line-height: 32px;
        text-align: left;
        color: $dark;
    }
    p {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        color: $dark;
        margin: 25px 0 15px;
    }

    .mobile-button{
        margin: 40px 0 0;
        @include media-breakpoint-down(md) {
            display: block;
            margin: 40px 0 0;
        }
        @include media-breakpoint-down(sm) {
            display: block;
            margin: 40px 0 0;
        }
        ul{
            display: flex;
            align-items: center;
            li{
                margin: 0 15px 0 0;
                &:last-child{
                    margin: 0;
                }
                a{
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.stream-block {
    padding: 0 0 250px;
    @include media-breakpoint-down(xl) {
        padding: 0 0 160px;
    }
    @include media-breakpoint-down(lg) {
        padding: 0 0 160px;
    }
    @include media-breakpoint-down(md) {
        padding: 0 0 160px;
    }
    @include media-breakpoint-down(sm) {
        padding: 0 0 160px;
    }
    .approval-details {
        padding: 0;
    }
    .main-img {
        img {
            width: 100%;
            margin: 0 0 15px;
        }
    }
}


.address-wrp{
    .title {
        h2{
            font-weight: 500;
        }
    }
    .address-box{
        span{

        }
    }
}