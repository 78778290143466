.social-profiles-section {
    .social-box {
        @include media-breakpoint-down(lg) {
            margin: 0 0 5rem;
        }
        img.icon{
            width: 31px;
            height: 31px;
            margin: 0 0 15px;
            color: $dark;
        }
        img {
            margin: 0 0 12px;
        }
        span {
            font-family: $arapey;
            font-weight: normal;
            font-style: italic;
            font-size: 16px;
            line-height: 1;
            text-align: left;
            color: $dark;
            margin-bottom: .5rem;
        }
        h2 {
            font-weight: bold;
            font-size: 32px;
            line-height: 32px;
            text-align: left;
            color: $dark;
            margin: 0 0 20px;
            text-transform: uppercase;
            @include media-breakpoint-down(lg) {
                font-size: 24px;
            }
            @include media-breakpoint-down(md) {
                font-size: 24px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 24px;
            }
        }
    }
}