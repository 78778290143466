$btn-sm-width: 9.6rem;
$btn-lg-width: 18.6rem;


$primary  :#000;
$purple  :#9B5EFF;
$coral  :#FF607C;
$yellow  :#E2B600;
$lightblue:#00C8E2;
$red: #FF0000;
$green: #198754;



$dark1:#000 !default;
$dark2:#13252B !default;
$dark3:rgba(0, 0, 0, 0.8) !default;
$dark4:rgba(0, 0, 0, 0.7) !default;
$dark5:rgba(0, 0, 0, 0.6) !default;
$dark6:rgba(0, 0, 0, 0.4) !default;
$dark7:rgba(0, 0, 0, 0.3) !default;
$dark8:rgba(0, 0, 0, 0.2)!default;
$dark9:rgba(0, 0, 0, 0.1)!default;


$light:#fff !default;
$light2:rgba(255,255,255, 0.9) !default;
$light3:rgba(255,255,255, 0.8) !default;
$light4:rgba(255,255,255, 0.7) !default;
$light5:rgba(255,255,255, 0.6) !default;
$light6:rgba(255,255,255, 0.4) !default;
$light7:rgba(255,255,255, 0.3) !default;
$light8:rgba(255,255,255, 0.2)!default;
$light9:rgba(255,255,255, 0.1)!default;



$success:#16B761;
$error:#E71447 !default;

$arapey: "Arapey";

// Color system
$yellow: #E2B600;
$cyan: #00C8E2;
$teal: #00E2C8;
$softred: #FD7777;
$paleviolet: #D9ADFF;
$pink: #FFB4F6;
$theme-colors: (
    "primary": $primary,
    "white": #FFF,
    "yellow": $yellow,
    "cyan": $cyan,
    "teal": $teal,
    "soft-red": $softred,
    "pale-violet": $paleviolet,
    "pink": $pink,
    "danger": $red,
    "success": $green,
    "inperson": #FFBF00,
    "venue": rgb(255,51,0),
    "recorded": rgb(153,153,51),
    "virtual": rgb(102,51,0),
    "livestream": rgb(102,102,153),
    "film": rgb(51,51,102)
);


// Typography
$font-family-base : "Proxima Nova", "proxima-nova";
$font-size-root: 62.5%;
$font-size-base: 1.4rem;
$line-height-base: 1.42857;
$h1-font-size: 5.2rem;
$h2-font-size: 1.4rem;
$h3-font-size: 3.2rem;
$h4-font-size: 1.8rem;
$h5-font-size: 1.6rem;

// Paragraphs
$paragraph-margin-bottom: $font-size-base * $line-height-base;

// Modals
$modal-inner-padding: 0;
$modal-content-color: #fff;
$modal-content-bg: transparent;
$modal-content-border-width: 0;
$modal-header-border-width: 0;
$modal-backdrop-opacity: .8;

// Buttons + Forms
$input-btn-padding-y: .75rem;
$input-btn-padding-x: 5.8rem;
$input-btn-font-size: 1.2rem;
$input-btn-padding-y-sm: $input-btn-padding-y;
$input-btn-padding-x-sm: 0;
$input-btn-font-size-sm: $input-btn-font-size;
$input-btn-padding-y-lg: $input-btn-padding-y;
$input-btn-padding-x-lg: 0;
$input-btn-font-size-lg: $input-btn-font-size;
$input-btn-focus-width: 0;

// Buttons
$btn-line-height: 1.5rem;
$btn-font-weight: 500;
$btn-border-radius: 2rem;
$btn-border-radius-sm: $btn-border-radius;
$btn-border-radius-lg: $btn-border-radius;

// Spacing
$spacers: (
    "0": 0,
    "5": .5rem,
    "10": 1rem,
    "15": 1.5rem,
    "20": 2rem,
    "30": 3rem,
    "35": 3.5rem,
    "50": 5rem,
    "60": 6rem,
    "70": 7rem,
    "80": 8rem,
    "100": 10rem,
    "120": 12rem,
    "130": 13rem,
    "140": 14rem,
    "150": 15rem,
    "170": 17rem,
    "180": 18rem
);

// Navbar
$navbar-toggler-focus-width: 0;

// Forms
$input-bg: transparent;
$input-focus-box-shadow: none;

// Alerts
$alert-padding-x: 0;

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1366px,
  xxxl: 1920px
);

// Grid containers
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%,
  xxl: 1196px,
  xxxl: 1652px
);
