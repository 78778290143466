/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.form-control::placeholder {
    color: $gray-500;
}
.form-control::-ms-input-placeholder {
    color: $gray-500;
}

.form-label {
    text-transform: uppercase;
}

.btn {
    text-transform: uppercase;
    &-sm {
        width: $btn-sm-width;
    }
    &-lg {
        width: $btn-lg-width;
    }
}