.swiper-button-prev, .swiper-button-next {
    height: 2rem;
    top: -.5rem;
    margin-top: 0;
    width: auto;
    left: auto;
    color: $black;
    z-index: 2;
    &:after {
        font-size: 2rem;
    }
}
.swiper-button-prev {
    right: 7.5rem;
    @include media-breakpoint-down(xxl) {
        right: 13.5rem;
    }
    @include media-breakpoint-down(xl) { 
        right: 10.5rem;
    }
    @include media-breakpoint-down(md) {
        right: 9.5rem;
    }
}
.swiper-button-next {
    right: 0;
    @include media-breakpoint-down(xxl) { 
        right: 6rem;
    }
    @include media-breakpoint-down(xl) { 
        right: 3rem;
    }
}