.approval-slider-wrp {
    padding-top: 0;
    padding-bottom: 180px;
    overflow: hidden;
    @include media-breakpoint-down(xl) {
        padding: 0 0 150px;
    }
    @include media-breakpoint-down(lg) {
        padding: 0 0 150px;
    }
    @include media-breakpoint-down(md) {
        padding: 0 0 150px;
    }
    @include media-breakpoint-down(sm) {
        padding: 0 0 150px;
    }
    .main-title {
        h2 {
            font-weight: bold;
            font-size: 32px;
            letter-spacing: 0.02em;
            line-height: 14px;
            text-align: left;
            color: $dark;
            opacity: 1;
            margin: 0 0 20px;
        }
    }
    .title-container {
        position: relative;
        .tiltebar {
            border-bottom: 1px solid $dark8;
            padding-bottom: 15px;
            h3 {
                font-weight: bold;
                font-size: 14px;
                letter-spacing: 0.02em;
                line-height: 14px;
                text-align: left;
                color: $dark;
                opacity: 1;
                text-transform: uppercase;
                margin: 0;
                display: block;
                width: 100%;
            }
        }
    }
    .swiper {
        padding-top: 42px;
        .swiper-wrapper {
            .slider-img {
                position: relative;
                margin: 0 0 15px;
                img {
                    width: 100%;
                }
                .play-img {
                    max-width: 100%;
                    position: absolute;
                    left: 15px;
                    bottom: 15px;
                    width: 60px;
                    height: 60px;
                    @include media-breakpoint-down(xl) {
                        height: 35px;
                        width: 35px;
                        top: 50%;
                        left: 50%;
                        bottom: auto;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                    }
                    @include media-breakpoint-down(lg) {
                        height: 35px;
                        width: 35px;
                        top: 50%;
                        left: 50%;
                        bottom: auto;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                    }
                    @include media-breakpoint-down(md) {
                        height: 35px;
                        width: 35px;
                        top: 50%;
                        left: 50%;
                        bottom: auto;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                    }
                    @include media-breakpoint-down(sm) {
                        height: 35px;
                        width: 35px;
                        top: 50%;
                        left: 50%;
                        bottom: auto;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                    }
                }
            }
            .slider-details {
                span {
                    font-weight: normal;
                    font-style: italic;
                    font-size: 16px;
                    line-height: 14px;
                    text-align: left;
                    color: $dark;
                    display: block;
                    font-family: $arapey;
                    margin: 0 0 5px;
                }
                h2 {
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 32px;
                    text-align: left;
                    color: $dark;
                    margin: 0 0 40px;
                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                    @include media-breakpoint-down(lg) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
                a {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: left;
                    color: $dark;
                    border-top: 2px solid $dark;
                    padding-top: 10px;
                    display: inline-block;
                    margin-right: 15px;
                    vertical-align: middle;
                }
            }
        }
        .container {
            position: relative;
        }
    }
}

.archive-wrp {
    padding: 100px 0 80px;
    .slider-details {
        h2 {
            small {
                font-weight: normal;
                font-style: italic;
                font-size: 16px;
                text-align: left;
                display: block;
            }
        }
    }
}

.approval-info {
    .library-wrp {
        .col-md-3.col-sm-6{
            padding-left: 6px;
            padding-right: 6px;
            @include media-breakpoint-down(md) {
                padding-left: 15px;
                padding-right: 15px;
            }
            @include media-breakpoint-down(sm) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .library-box {
            @include media-breakpoint-down(xl) {
                margin: 0 0 40px;
            }
            @include media-breakpoint-down(lg) {
                margin: 0 0 40px;
            }
            @include media-breakpoint-down(md) {
                margin: 0 0 40px;
            }
            @include media-breakpoint-down(sm) {
                margin: 0 0 40px;
            }
            .slider-img {
                position: relative;
                margin: 0 0 15px;
                img {
                    width: 100%;
                }
            }
            .slider-details {
                span {
                    font-weight: normal;
                    font-style: italic;
                    font-size: 16px;
                    line-height: 14px;
                    text-align: left;
                    color: $dark;
                    display: block;
                    margin: 0 0 5px;
                    font-family: $arapey;
                }
                h2 {
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 32px;
                    text-align: left;
                    color: $dark;
                    margin: 0 0 10px;
                    text-transform: uppercase;
                }
                p {
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: -0.01em;
                    line-height: 16px;
                    text-align: left;
                    color: $dark;
                    margin: 0 0 40px;
                    strong {
                        display: block;
                        margin: 0 0 10px;
                        font-weight: 700;
                    }
                }
                a {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: left;
                    color: $dark;
                    border-top: 2px solid $dark;
                    padding-top: 10px;
                    display: inline-block;
                    margin-right: 15px;
                    vertical-align: middle;
                }
            }
        }
    }
}
