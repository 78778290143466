.work-slider {
    overflow: hidden;
    padding-top: 2rem;
    margin-top: 10rem;
    margin-bottom: 8rem;

    .title-container {
        position: relative;
        .tiltebar {
            .section-heading {
                margin-bottom: 0;
            }
        }
    }
    
    .swiper {
        width: 100%;
        padding-top: 40px;
        overflow: visible;
        @include media-breakpoint-down(lg) {
            padding-top:23px;
        }
        @include media-breakpoint-down(md) {
            padding-top:23px;
        }
        @include media-breakpoint-down(sm) {
            padding-top:23px;
        }

        .swiper-wrapper {
            .slider-img {
                position: relative;
                margin: 0 0 1rem;
                img {
                    width: 100%;
                }
                .play-img {
                    max-width: 100%;
                    position: absolute;
                    left: 15px;
                    bottom: 15px;
                    width: auto;
                }
                &.placeholder {
                    height: 0;
                    padding-bottom: (541/401)*100%;
                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: #6E6E6E;
                    }
                }
                &.video-thumb {
                    height: (224/388)*100%;
                }
            }
            .slider-details {
                h2 {
                    small {
                        font-weight: normal;
                        font-style: italic;
                        font-size: 16px;
                        text-align: left;
                        display: block;
                    }
                }
                span{
                    font-family: $arapey;
                    font-weight: normal;
                    font-style: italic;
                    font-size: 1.6rem;
                    line-height: 2rem;
                }
                .work-badges {
                    margin-bottom: .5rem;
                }
                .title {
                    font-weight: bold;
                    font-size: 3.2rem;
                    line-height: 1;
                    text-align: left;
                    color: $dark;
                    margin: 0 0 2.4rem;
                    text-transform: uppercase;
                    max-width: 100%;
                    word-wrap: break-word;
                    hyphens: auto;
                    @include media-breakpoint-down(xl) {
                        font-size: 26px;
                        line-height: 30px;
                        br {
                            display: none;
                        }
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 2rem;
                        line-height: 1;
                    }
                }
                p {
                    font-weight: normal;
                    font-size: 12px;
                    letter-spacing: 0.02em;
                    text-align: left;
                    color: $dark;
                    margin: 0 0 50px;
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
                .more {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: left;
                    color: $dark;
                    border-top: 2px solid $dark;
                    padding-top: 10px;
                    display: inline-block;
                    margin-right: 15px;
                    vertical-align: middle;
                }
            }
        }
        .container {
            position: relative;
        }
        
    }
}