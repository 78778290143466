.site-footer {
    padding: 0 0 2rem;
    position: relative;
    
    &-links {
        padding-top: 2.4rem;
        border-top: 1px solid rgba(0,0,0,.4);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
            &:first-child {
                width: 21.32%;
                padding-right: 6rem;
                position: relative;
                @include media-breakpoint-down(xl) {
                    padding-right: 0;
                }
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
                p {
                    max-width: 23rem;
                    @include media-breakpoint-up(lg) {
                        margin-bottom: 0;
                        width: 18.9rem;
                    }
                }
                .social-ic {
                    font-size: 0;
                    position: absolute;
                    right: -15%;
                    top: 0;
                    @include media-breakpoint-down(xl) {
                        top: auto;
                        bottom: 0;
                    }
                    @include media-breakpoint-down(lg) {
                        right: 0;
                        top: 0;
                    }
                    a {
                        height: 25px;
                        width: 25px;
                        align-items: center;
                        border: 1px solid $dark;
                        color: $dark;
                        font-size: 12px;
                        margin: 0 10px 0 0;
                        border-radius: 100%;
                        display: inline-block;
                        text-align: center;
                        line-height: 25px;
                        -webkit-transition: all 0.3s linear;
                        transition: all 0.3s linear;
                        @include media-breakpoint-down(xl) {
                            margin: 0 10px 0 0;
                        }
                        @include media-breakpoint-down(lg) {
                            margin: 0 3rem 0 0;
                        }
                        &:last-child {
                            //border-radius: 4px;
                            margin: 0;
                        }
                        &:hover {
                            color: $light;
                            background-color: $dark;
                        }
                    }
                }
            }
            &:nth-child(2) {
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
            }
            &:last-child {
                padding-right: 3rem;
                @include media-breakpoint-down(xl) {
                    padding-right: 0;
                }
            }
            @include media-breakpoint-down(lg) {
                width: 25%;
                padding: 0;
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:last-child{
                    width: 20%;
                }
            }
            p {
                font-weight: 500;
                font-size: 12px;
                letter-spacing: 0.02em;
                line-height: 14px;
                text-align: left;
                color: $dark;
                a {
                    display: block;
                    font-weight: bold;
                    color: $dark;
                    margin: 10px 0 0;
                    -webkit-transition: all 0.3s linear;
                    transition: all 0.3s linear;
                    &:not(.btn):hover {
                        -webkit-transform: translate(5px, 0);
                        transform: translate(5px, 0);
                        color: $dark;
                    }
                }
            }

            .link-box {
                h4 {
                    font-weight: bold;
                    font-size: 12px;
                    letter-spacing: 0.02em;
                    text-align: left;
                    color: $dark;
                    margin-bottom: 1rem;
                }
                a {
                    font-weight: normal;
                    font-size: 12px;
                    letter-spacing: 0.02em;
                    line-height: 14px;
                    text-align: left;
                    color: $dark;
                    display: block;
                    padding: 0 0 4px;
                    -webkit-transition: all 0.3s linear;
                    transition: all 0.3s linear;
                    &:hover {
                        -webkit-transform: translate(5px, 0);
                        transform: translate(5px, 0);
                    }
                }
            }
        }
    }

    .copyright {
        font-weight: normal;
        font-size: 12px;
        letter-spacing: 0.02em;
        line-height: 14px;
        color: $dark;
        margin: 15px 0 0;
        @include media-breakpoint-down(sm) {
            margin: 0;
        }
        span{
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
    }
}
