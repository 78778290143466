.news-wrp {
    padding: 50px 0 0;

    .back-arrow {
        display: block;
        img {
            max-width: 100%;
            margin: 0 0 50px;
        }
    }

    .titlebar {
        padding: 0 0 20px;
        border-bottom: 1px solid $dark6;
        margin: 0 0 50px;
    }

    .news-info {
        padding: 0 0 130px;
        p {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
            color: $dark;
            margin: 0 0 20px;
        }
    }

    .title {
        h2 {
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.02em;
            line-height: 14px;
            text-align: left;
            color: $dark;
            opacity: 1;
            border-bottom: 1px solid $dark6;
            text-transform: uppercase;
            padding-bottom: 10px;
            margin: 0 0 40px;
        }
    }
    .recent-wrp {
        .col-md-4.col-sm-4{
            padding-left: 7.5px;
            padding-right: 7.5px;
        }
        .recent-slider {
            display: none;
            @include media-breakpoint-down(lg) {
                display: block;
            }
            @include media-breakpoint-down(md) {
                display: block;
            }
            @include media-breakpoint-down(sm) {
                display: block;
            }
        }
        .recent-box {
            img {
                max-width: 100%;
            }
            span {
                margin: 12px 0 5px;
                display: block;
                font-family: $arapey;
                font-weight: normal;
                font-style: italic;
                font-size: 16px;
                line-height: 14px;
                text-align: left;
                color: $dark1; 
                @include media-breakpoint-down(sm) {
                    margin: 12px 0 7px;
                }              
            }
            h3 {
                font-weight: bold;
                font-size: 32px;
                line-height: 32px;
                text-align: left;
                color: $dark;
                margin: 0 0 20px;
                @include media-breakpoint-down(lg) {
                    font-size: 20px;
                    line-height: 20px;
                }
                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    line-height: 20px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                    line-height: 20px;
                }
            }
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                text-align: left;
                color: $dark;
                margin: 0 0 40px;
                @include media-breakpoint-down(lg) {
                    font-size: 12px;
                }
                @include media-breakpoint-down(md) {
                    font-size: 12px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                }
            }
            a {
                font-weight: 600;
                font-size: 14px;
                line-height: 14px;
                text-align: left;
                color: $dark;
                padding-top: 10px;
                border-top: 1px solid $dark;
            }
        }
    }
}