b, strong {
    font-weight: 700!important;
}

.title {
    text-transform: uppercase;
}

h1, h2, h3, h4, h5 {
    text-transform: uppercase;
}

h2, .h2 {
    line-height: 1;
    font-weight: 700;
}

h3, .h3 {
    line-height: 1;
    font-weight: 700;
    margin-bottom: 2rem;
}

h4 , .h4 {
    line-height: 1;
    font-weight: 400;
    margin-bottom: 3rem;
}

h5, .h5 {
    font-weight: 500;
}