.team-members-section {

    .section-heading {
        border-bottom: none;
    }

    .about-slider-wrp {
        padding-top: 1rem;
        margin-bottom: 3.5rem;
        overflow: hidden;
        @include media-breakpoint-up(lg) {
            margin-bottom: 7.5rem;
        }
        &:last-child {
            margin-bottom: 0;
        }
        .main-title {
            h2 {
                font-weight: bold;
                font-size: 32px;
                letter-spacing: 0.02em;
                line-height: 14px;
                text-align: left;
                color: $dark;
                opacity: 1;
                margin: 0 0 20px;
                @include media-breakpoint-down(sm) {
                   line-height: 32px;
                   margin: 0 0 100px;
                }
                span{
                    @include media-breakpoint-down(sm) {
                        display: block;
                    }
                }
            }
        }
        .title-container {
            position: relative;
            .tiltebar {
                border-bottom: 1px solid $dark8;
                padding-bottom: 15px;
                h3 {
                    font-weight: 500;
                    font-size: 14px;
                    letter-spacing: 0.02em;
                    line-height: 14px;
                    text-align: left;
                    color: $dark;
                    opacity: 1;
                    text-transform: uppercase;
                    margin: 0;
                    display: block;
                    width: 100%;
                }
            }
        }
        .swiper {
            padding-top: 40px;
            overflow: visible;
            .swiper-wrapper {
                .slider-img {
                    position: relative;
                    margin: 0 0 15px;
                    img {
                        width: 100%;
                    }
                    &.placeholder {
                        height: 0;
                        padding-bottom: (380/287)*100%;
                        &:after {
                            content: "";
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            background-color: #6E6E6E;
                        }
                    }
                }
                .slider-details {
                    p {
                        font-weight: 300;
                        font-size: 1.6rem;
                        line-height: 2rem;
                        text-align: left;
                        color: $dark;
                        margin: 0 0 25px;
                        strong {
                            display: block;
                            font-weight: bold;
                        }
                    }
                    .more {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 14px;
                        text-align: left;
                        color: $dark;
                        border-top: 2px solid $dark;
                        padding-top: 10px;
                        display: inline-block;
                        margin-right: 15px;
                        vertical-align: middle;
                    }
                }
            }
            .container {
                position: relative;
            }
        }
    }
}