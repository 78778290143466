.hero-wrp {
    width: 100%;
    overflow: hidden;
    .swiper {
        height: 600px;
        width: 100%;
        @include media-breakpoint-down(lg) {
            height: 500px;
        }
        @include media-breakpoint-down(md) {
            height: 500px;
        }
        @include media-breakpoint-down(sm) {
            height: 500px;
        }
        img {
            width: 100%;
            height: 600px;
            object-fit: cover;
            @include media-breakpoint-down(lg) {
                height: 500px;
            }
            @include media-breakpoint-down(md) {
                height: 500px;
            }
            @include media-breakpoint-down(sm) {
                height: 500px;
            }
        }

        .back {
            position: absolute;
            left: 15px;
            top: 170px;
            bottom: auto;
            @include media-breakpoint-down(xl) {
                top: 140px;
                left: 20px;
            }
            @include media-breakpoint-down(lg) {
                top: 125px;
                left: 20px;
            }
            @include media-breakpoint-down(md) {
                top: 125px;
                left: 20px;
            }
            @include media-breakpoint-down(sm) {
                top: 125px;
                left: 20px;
            }
            img {
                height: auto;
                width: auto;
                object-fit: contain;
                @include media-breakpoint-down(lg) {
                    display: none;
                }
                @include media-breakpoint-down(md) {
                    display: none;
                }
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
        .slider-details {
            position: absolute;
            left: 0;
            bottom: 4.64rem;
            z-index: 9;
            @include media-breakpoint-down(xxl) { 
                left: 6rem;
            }
            @include media-breakpoint-down(xl) {
                left: 3rem;
                bottom: 4.24rem;
            }
            @include media-breakpoint-down(lg) {
                bottom: 3.94rem;
            }
            p {
                font-weight: normal;
                font-size: 14px;
                letter-spacing: 0.02em;
                line-height: 14px;
                text-align: left;
                color: $light;
                text-transform: uppercase;
                opacity: 1;
                margin: 0 0 10px;
                @include media-breakpoint-down(lg) {
                    display: table;
                    margin: 0 0 5px;
                }
                @include media-breakpoint-down(md) {
                    display: table;
                    margin: 0 0 5px;
                }
                @include media-breakpoint-down(sm) {
                    display: table;
                    margin: 0 0 5px;
                }
            }
            h2 {
                font-weight: bold;
                font-size: 52px;
                line-height: 46px;
                text-align: left;
                color: $light;
                margin: 0 0 15px;
                text-transform: uppercase;
                @include media-breakpoint-down(xl) {
                    font-size: 35px;
                    line-height: 42px;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 44px;
                    line-height: 36px;
                    margin: 0 0 10px;
                }
                @include media-breakpoint-down(md) {
                    font-size: 44px;
                    line-height: 36px;
                    margin: 0 0 10px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 44px;
                    line-height: 36px;
                    margin: 0 0 10px;
                }
            }
            a:not(.btn) {
                font-weight: normal;
                font-size: 1.4rem;
                line-height: 1;
                text-align: left;
                color: $light;
                border-top: 2px solid $light;
                padding-top: 10px;
                display: inline-block;
                margin-right: 15px;
                vertical-align: middle;
                font-weight: 700;
                img {
                    height: auto;
                    width: auto;
                }
            }
            a.play-img {
                border: none;
                margin: 0;
            }
            .btn-book {
                padding-left: 2.5rem;
                padding-right: 2.5rem;
            }
        }

        .container {
            height: auto;

            position: relative;
            .swiper-button-next,
            .swiper-button-prev {
                top: auto;
                bottom: 7rem;
                &:after {
                    color: $light;
                }
                @include media-breakpoint-down(lg) {
                    display: none;
                }
                @include media-breakpoint-down(md) {
                    display: none;
                }
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
            .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                top: auto;
                left: auto;
                right: 0;
                bottom: 4.94rem;
                position: absolute;
                z-index: 999;
                @include media-breakpoint-down(xxl) { 
                    right: 6rem;
                }
                @include media-breakpoint-down(xl) { 
                    right: 3rem;
                }
                span.swiper-pagination-bullet {
                    width: 85px;
                    height: 4px;
                    background: $light;
                    opacity: 0.35;
                    border-radius: 0;
                    right: 0;
                    margin-right: 15px;
                    -webkit-animation: all 0.3s linear;
                    transition: all 0.3s linear;
                    &:last-child {
                        margin: 0;
                    }
                    @include media-breakpoint-down(md) {
                        width: 45px;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 45px;
                    }

                }
                span.swiper-pagination-bullet.swiper-pagination-bullet-active {
                    opacity: 1;
                }
            }
        }
    }
}
