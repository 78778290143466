@import "component/header";
@import "component/footer";
@import "component/newsletter";
@import "component/hero";

.section-heading {
    
    
    letter-spacing: 0.02em;
    
    border-bottom: 1px solid $dark6;
    padding-bottom: 10px;
    margin: 0 0 40px;
        
}

@import "component/gallery";
@import "component/works-listing";
@import "component/news-listing";
@import "component/quote";
@import "component/review";
@import "component/members-listing";
@import "component/google-map";
@import "component/merchandise";
@import "component/audibles-listing";
@import "component/mini-work";
@import "component/resources-listing.scss";
@import "component/short-films-listing";
@import "component/social-profiles";
@import "component/text-blocks";
@import "component/my-nzdc/checkout";
@import "component/calendar";