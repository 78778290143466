.quote-section {
    blockquote {
        font-weight: 300;
        text-transform: uppercase;
        font-size: 3.6rem;
        line-height: 1;
        text-align: center;
        //font-family: $arapey;
        margin: 0 0 15px;
        font-style: italic;
        @include media-breakpoint-down(md) {
            font-size: 20px;
            line-height: 24px;
            width: 80%;
            margin: 0 auto 15px;
            br{
                display: none;
            }
        }
        @include media-breakpoint-down(sm) {
            font-size: 20px;
            line-height: 24px;
            width: 80%;
            margin: 0 auto 15px;
            br{
                display: none;
            }
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .author {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.5rem;
        letter-spacing: 0.02em;
        text-align: center;
        color: $dark;
        margin: 0;
        &-description {
            font-weight: 400;
            font-style: normal;
        }
    }
}