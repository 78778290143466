.google-map-section {
    padding: 0 0 120px;
    .map-block {
        height: 600px;
        width: 100%;
        @include media-breakpoint-down(md) {
            height: 300px;
        }
        @include media-breakpoint-down(sm) {
            height: 300px;
        }
        iframe {
            height: 100%;
            width: 100%;
        }
    }
}